import React, { useState } from "react"
import { Carousel, Col, Container, Row, Modal } from "react-bootstrap"
import { graphql } from "gatsby"
import CloseIcon from "@mui/icons-material/Close"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import Layout from "../components/layout/Index"
import ListGallery from "../components/content/gallery/list/Index"
import { withTrans } from "../i18n/withTrans"

const Index = ({ data, location: { origin } }) => {
  const [show, setShow] = useState(false)
  const [imgIdx, setImgIdx] = useState(0)
  const gallery = data?.wpGalleryGks

  const regexGetHrefAttribute = /<a[^>]*href=["']([^"']*)["']/gm
  const getGalleryData = wysiwygMultipleGallery => {
    const getHrefAttribute = wysiwygMultipleGallery?.match(
      regexGetHrefAttribute
    )
    const getAllGalleryLink = getHrefAttribute?.map(
      item => `${item.substring(9, item.length - 1).replace(/ /g, "%20")}`
    )
    return getAllGalleryLink
  }

  const getGalleryValue = getGalleryData(gallery.gallery.editorRegGambar)

  const handleClose = () => setShow(false)

  const handleShow = idx => {
    setImgIdx(idx)
    setShow(true)
  }

  const handleSelect = (selectedIndex, e) => {
    setImgIdx(selectedIndex)
  }

  return (
    <Layout>
      <Container className="galeri-detail remove-padding">
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <div className="close-btn" onClick={handleClose}>
            <CloseIcon />
          </div>
          <Modal.Body>
            <Carousel
              activeIndex={imgIdx}
              onSelect={handleSelect}
              nextIcon={<NavigateNextIcon />}
              prevIcon={<NavigateNextIcon />}
              className="gallery-carousel"
            >
              {getGalleryValue?.map(item => (
                <Carousel.Item>
                  <img className="galeri-detail-img" src={item || ""} alt="" />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
        <label className="title">{gallery?.title}</label>
        <span className="date">{gallery?.gallery?.tanggalRegulasi}</span>
        <div
          dangerouslySetInnerHTML={{ __html: gallery?.content }}
          className="content"
        />
        <Row>
          {getGalleryValue?.map((item, idx) => (
            <Col md={4} onClick={() => handleShow(idx)}>
              <ListGallery imgSrc={item} isDetail />
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpGalleryGks(id: { eq: $id }) {
      id
      content
      title
      gallery {
        tanggalRegulasi
        editorRegGambar
      }
    }
  }
`

export default withTrans(Index)
